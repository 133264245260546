import React from "react"

import Layout from "../components/layout"

const NotFoundPage = () => (
  <Layout>
    <h1>Página no encontrada</h1>
    <p>La página no existe.</p>
  </Layout>
)

export default NotFoundPage
